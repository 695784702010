import React from "react";
import Navbar from "components/Navbar/navbar";
import Intro4 from "components/Intro4/intro4";
import LightTheme from "layouts/Light";

const Homepage5 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);
  return (
    <LightTheme>
      <Navbar nr={navbarRef} lr={logoRef} theme="themeL" />
      <Intro4 blackStar />
    </LightTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>TYMONET - Tworzenie stron WWW</title>
    </>
  )
}

export default Homepage5;